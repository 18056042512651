import React from 'react';
import Spacing from '@earnup/olympic-lib/Spacing';
import Typography from '@earnup/olympic-lib/Typography';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';

export const styles = theme => ({
  title: {
    fontWeight: 900,
    textAlign: 'center',
    position: 'relative',
    zIndex: 100,
  },
});

const useStyles = makeStyles(styles);

const StepTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <Spacing marginTop={2} marginBottom={3}>
      <Typography dataQa="typography-h4" variant="h4" className={classes.title}>
        {title}
      </Typography>
    </Spacing>
  );
};

export default StepTitle;
