import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import padStart from 'lodash/padStart';
import reduce from 'lodash/reduce';
/**
 * Get the value of a field.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @param {string} key data key (e.g. scheduleType in values.scheduleType, errors.scheduleType, etc.)
 * @returns {any} current field value
 */
export const getValue = (props, key) => props.values[key];

/**
 * Get the error state of a field.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @param {string} key data key (e.g. scheduleType in values.scheduleType, errors.scheduleType, etc.)
 * @returns {boolean} current field error state
 */
export const getError = (props, key) => Boolean(props.touched[key] && props.errors[key]);

/**
 * Get the helper text of a field.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @param {string} key data key (e.g. scheduleType in values.scheduleType, errors.scheduleType, etc.)
 * @returns {string} current field helper text
 */
export const getHelperText = (props, key) => (getError(props, key) ? props.errors[key] : undefined);

/**
 * Get the onChange handler of a field.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @param {string} key data key (e.g. scheduleType in values.scheduleType, errors.scheduleType, etc.)
 * @returns {function} onChange handler for the field
 */
export const getOnChange = (props, key) => props.handleChange(key);

/**
 * Get the onBlur handler of a field.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @param {string} key data key (e.g. scheduleType in values.scheduleType, errors.scheduleType, etc.)
 * @returns {function} onBlur handler for the field
 */
export const getOnBlur = (props, key) => props.handleBlur(key);

/**
 * Get the loading state of the form.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @returns {boolean} form loading state
 */
export const getLoading = props => props.isSubmitting;

/**
 * Get the onSubmit handler of the form.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @returns {boolean} form submit handler
 */
export const getOnSubmit = props => props.handleSubmit;

/**
 * Get the changed bool of a field.
 *
 * @param {object} props FormikBag (e.g. { values, errors, touched, ...etc })
 * @param {string} key data key (e.g. scheduleType in values.scheduleType, errors.scheduleType, etc.)
 * @returns {boolean} current field changed
 */
export const isChanged = (props, key) => {
  const initialValue = props.initialValues[key];
  const currentValue = getValue(props, key);
  return initialValue === currentValue;
};

// TODO move outta here
const diffObject = (object1, object2) =>
  omitBy(object1, (value, key) => isEqual(object2[key], value));

/**
 * Get only updated values.
 *
 * @param {object} initialValues
 * @returns {object} diffedValues
 */
export const getOnlyChangedValues = (initialValues, onSubmit) => (...formOptions) => {
  const [formValues, ...rest] = formOptions;

  // this is a shallow diff
  const diffedValues = diffObject(formValues, initialValues);
  return onSubmit(diffedValues, ...rest);
};

export const getTargetValue = event => event.target.value;

// TODO move out of forms utils. this is specific to the escalation form.
export const formatEscalation = values => {
  return reduce(
    values,
    (str, value, key) => {
      return `${str}${key}: ${padStart(value, 20)}\n`;
    },
    '',
  );
};

export const resetOnSuccess = (submitFunc, initialValue = {}) => async (values, actions) => {
  await submitFunc(values, actions);

  // If the request fails this will not be triggered
  actions.resetForm(initialValue);
};

export const isShiftKeyPressed = e => e.shiftKey;

export const isEnterKeyPressed = e => e.keyCode === 13;

export const isEscapeKeyPressed = e => e.keyCode === 27;

export const isShiftEnterKeysPressed = e => isEnterKeyPressed(e) && isShiftKeyPressed(e);
