const ROOT = process.env.PUBLIC_URL;
const createResource = relativePath => `${ROOT}${relativePath}`;

// Icons
export const ELIGIBLE_MOBILE = createResource('/icons/congratulations-illustration-mobile.png')
export const ELIGIBLE_DESKTOP = createResource('/icons/congratulations-illustration-mobile.png')
export const NOT_ELIGIBLE_MOBILE = createResource('/icons/not-eligible-illustration.png')
export const NOT_ELIGIBLE_DESKTOP = createResource('/icons/not-eligible-illustration.png')

// Logo
export const EARNUP_LOGO = createResource('/logos/earnup_logo_white.png');

// Documents
export const PRIVACY_POLICY_PDF = createResource('/docs/privacy-policy.pdf');
