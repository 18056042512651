import React, { useState, useRef } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Header from './Header';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import ScreenEligible from './ScreenEligible';
import ScreenForm from './ScreenForm';
import ScreenNotEligible from './ScreenNotEligible';
import NotFound from './NotFound';
import Paper from '@earnup/olympic-lib/Paper';

export const styles = theme => ({
  root: {
    background: '#F7FAF8', // did not see theme.palette.background in oly createTheme.js
    fontSize: theme.spacing.unit * 2,
  },
  wrapper: {
    margin: 'auto',
    paddingRight: '16px',
    paddingLeft: '16px',
    paddingTop: '32px',
    paddingBottom: '32px',
    },
  paper: {
    padding: 0,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down('xs')]: {
      minWidth: '288px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '32px',
      paddingBottom: '40px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '763px',
      paddingLeft: '97px',
      paddingRight: '97px',
      paddingTop: '56px',
      paddingBottom: '74px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '763px',
      paddingLeft: '97px',
      paddingRight: '97px',
      paddingTop: '56px',
      paddingBottom: '74px',
    },
  },
});

const useStyles = makeStyles(styles);

const Matchup = props => {
  const classes = useStyles();
  const anonymousIdRef = useRef('');
  const firstNameRef = useRef('');
  const [callGreenpath, setCallGreenpath] = useState(false);
  const [eligible, setEligible] = useState(null);
  
  const containerForm = (
  <ScreenForm
    anonymousIdRef={anonymousIdRef}
    firstNameRef={firstNameRef}
    setCallGreenpath={setCallGreenpath}
    setEligible={setEligible}
    query={props.location.search}
  />
  );

  const containerEligible = (
  <ScreenEligible
    anonymousIdRef={anonymousIdRef}
    callGreenpath={callGreenpath}
    eligible={eligible}
    firstNameRef={firstNameRef}
  />
  );

  const containerNotEligible = (
  <ScreenNotEligible eligible={eligible} firstNameRef={firstNameRef} />
  );

  return ( 
    <div className={classes.background}>
      <Header />
        <div className={classes.wrapper}>
          <Paper className={classes.paper}>
            <Switch>
              <Route
                exact
                path="/matchup/enroll/"
                render={() => {
                  return (
                    (() => {
                      if (eligible === null) return containerForm;
                      if (eligible) return <Redirect to="/matchup/enroll/success" />
                      if (!eligible) return <Redirect to="/matchup/enroll/other" />
                    })()
                  );
                }}
              />
              <Route
                exact
                path="/matchup/enroll/success"
                render={() => eligible === true ? containerEligible : <Redirect to="/matchup/enroll" />}
              />
              <Route
                exact
                path="/matchup/enroll/other"
                render={() => eligible === false ? containerNotEligible : <Redirect to="/matchup/enroll" />}
              />    
              <Route component={NotFound} />
            </Switch>
          </Paper>
        </div>
    </div>
  );
};

export default Matchup;