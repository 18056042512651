// Headlines
export const HEAD1 = 'Provide the information below and see if you\'re eligible!';
export const SUBHEAD1 = 'We\'re excited to help you save for a down payment! Complete the questions below.';
export const HEAD2 = 'You’re one step closer to homeownership.';
export const SUBHEAD2 = 'A representative will call you within the next 2 business days. Here’s what you need to do now:';
export const SUBHEAD2_GREENPATH = 'You still need to call Homeownership Preservation Foundation (HPF) directly for your 30-minute personal homebuyer readiness assessment. Follow these steps:';
export const HEAD3 = 'we can help you achieve other financial goals!';
export const SUBHEAD3 = 'Our homebuyer readiness program isn\'t quite right for you, but we can still help! As a consumer-first financial services company, we\'re always coming out with new products. We\'ll email you so that you are the first to know what we have to offer.';
export const HEAD_NOT_FOUND = `Error 404`;
export const SUBHEAD_NOT_FOUND = `We've searched everywhere but we can't find the page you're looking for.`

// Steps documents, contact information, content
export const DOC_A = 'last 30-45 days of pay stubs';
export const DOC_B = 'your monthly spending plan';
export const PHONE = '(844) 218-6937';
export const HOURS = '9AM - 6PM EST';
export const CLOSING_TITLE = `Not a good time to call?`
export const CLOSING_CONTENT = `No worries. We’ll send you an email with all of the information you need, so you can finish your enrollment when you’re ready.`;

// Steps to take if eligible, lead submits time for call
export const ELIGIBLE_STEPS = [
  {
    number: 1,
    title: `Let us know the best time of day to call you.`,
    content: `The call will last about 30 minutes.`,
  }, 
  {
    number: 2,
    title: 'Get your documents ready!',
    content: `To prepare for your appointment please gather the <b>${DOC_A}</b>. Alternative proof of income documents may also be acceptable. This includes either a copy of last year’s federal tax return, or wage and tax statements like a W-2 or 1099.<br><br>Additionally the HPF representative will review <b>${DOC_B}</b>, so if possible, please have an idea of your monthly expenses.`
  },
  {
    number: 3,
    title: 'A HPF representative will call you within the next 2 business days.',
    content: 'Expect a call from an Homeownership Preservation Foundation (HPF) representative! HPF will call from an 888 number for your personal homebuyer readiness assessment.',
  },
];

// Steps to take if eligible, lead calls greenpath
export const ELIGIBLE_STEPS_GREENPATH = [
  {
    number: 1,
    title: 'Get your documents ready!',
    content: `Please gather the <b>${DOC_A}</b>. Alternative proof of income documents may also be acceptable. This includes either a copy of last year’s federal tax return, or wage and tax statements like a W-2 or 1099.<br><br>Additionally the HPF representative will review <b>${DOC_B}</b>, so if possible, please have an idea of your monthly expenses.`
  },
  {
    number: 2,
    title: `Call: ${PHONE}`,
    content: 'Contact HPF directly for your personal homebuyer readiness assessment.',
  },
];