import React from 'react';
import Button from '@earnup/olympic-lib/Button';
import Grid from '@earnup/olympic-lib/Grid';
import Link from '@earnup/olympic-lib/Link';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import StepContent from './StepContent';
import StepDivider from './StepDivider';
import StepTitle from './StepTitle';
import Typography from '@earnup/olympic-lib/Typography';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ELIGIBLE_STEPS_GREENPATH,
  PHONE,
  HOURS,
  CLOSING_TITLE,
  CLOSING_CONTENT,
} from './constants/copy';

export const styles = theme => ({
  phoneButton: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 6,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '276px',
    },
  },
  phoneIcon: {
    marginRight: theme.spacing.unit,
  },
  phoneLink: {
    color: theme.palette.primary.contrastText,
  },
});

const useStyles = makeStyles(styles);

const CustomerCallGreenpath = () => {
  const classes = useStyles();

  return (
    <>
        <Grid container direction="column" alignItems="center">
          <StepDivider number={ELIGIBLE_STEPS_GREENPATH[0].number} />
          <StepTitle title={ELIGIBLE_STEPS_GREENPATH[0].title}/>
          <StepContent content={ELIGIBLE_STEPS_GREENPATH[0].content} />
        </Grid>

        <Grid container direction="column" alignItems="center">
          <StepDivider number={ELIGIBLE_STEPS_GREENPATH[1].number} />
          <StepTitle title={ELIGIBLE_STEPS_GREENPATH[1].title}/>
          <Typography dataQa="call-hours" variant="body1"
          >
            <b>{`HPF is available between: ${HOURS}`}</b>
          </Typography>
          <StepContent content={ELIGIBLE_STEPS_GREENPATH[1].content} />
          <Button dataQa="phone-button" variant="contained" className={classes.phoneButton}>
            <FontAwesomeIcon icon={faPhone} className={classes.phoneIcon}/>
            <Link
              variant="text"
              dataQa="phone-link"
              href={`tel:${PHONE}`}
              className={classes.phoneLink}
            >
              {PHONE}
            </Link>
          </Button>
          <Typography dataQa="closing-title" variant="body1">
            {CLOSING_TITLE}
          </Typography>
          <StepContent content={CLOSING_CONTENT} />
        </Grid>
    </>
  );
};

export default CustomerCallGreenpath;
