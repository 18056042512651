import React from 'react';
import { Formik } from 'formik';
import {
  getValue,
  getError,
  getHelperText,
  getOnChange,
  getOnBlur,
  getLoading,
  getOnSubmit,
} from '../utils/forms';
import useFormSubmit from '../hooks/useFormSubmit';
import TimeFormForm from './TimeFormForm';
import createValidationSchema from './TimeFormFormValidation';

const TimeForm = ({ onSubmit: onSubmitProp, initialValues, leadData, showform, calltime }) => {
  const [success, onSubmit] = useFormSubmit(onSubmitProp);
  const validationSchema = createValidationSchema();

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        time: '',
      }}
      render={props => {
        const timeKey = 'time';

        return (
          <TimeFormForm
            loading={getLoading(props)}
            success={success}
            time={getValue(props, timeKey)}
            timeError={getError(props, timeKey)}
            timeHelperText={getHelperText(props, timeKey)}
            onTimeChange={getOnChange(props, timeKey)}
            onTimeBlur={getOnBlur(props, timeKey)}
            onSubmit={getOnSubmit(props)}
            leadData={leadData}
            showform={showform}
            calltime={calltime}
          />
        );
      }}
    />
  );
};

export default TimeForm;
