import React from 'react';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';

export const styles = theme => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  image: {
    height: theme.spacing.unit * 7.5,
    width: theme.spacing.unit * 12.5,
  }
});

const useStyles = makeStyles(styles);

const Image = ({ alt, src }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageContainer}>
      <img className={classes.image} alt={alt} src={src}></img>
    </div>
  );
};

export default Image;