import React from 'react';
import { EARNUP_LOGO } from './constants/resources';
import Grid from '@earnup/olympic-lib/Grid';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';

export const styles = theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.between('xs', 'sm')]: {
      height: theme.spacing.unit * 7.125, // '57px'
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing.unit * 8.75, // '70px'
    },
  },
  grid: {
    height: '100%',
  },
  logo: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginLeft: theme.spacing.unit * 2,
      width: theme.spacing.unit * 6.5, // '52px'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing.unit * 7.125,
      width: theme.spacing.unit * 7.75, // '62px'
    },
  },
});

const useStyles = makeStyles(styles);

const Header = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Grid container alignContent="center" className={classes.grid}>
        <img className={classes.logo} src={EARNUP_LOGO} alt="earnup-logo"/>
      </Grid>
    </header>
  );
};

export default Header;