import React from 'react';
import CardContent from '@earnup/olympic-lib/CardContent';
import Headline from './Headline';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import { HEAD_NOT_FOUND, SUBHEAD_NOT_FOUND } from './constants/copy';

export const styles = theme => ({
  card: {
    width: '100%',
    padding: 0,
  },
});

const useStyles = makeStyles(styles);

const ScreenNotFound = () => {
  const classes = useStyles();

  return (
    <CardContent className={classes.card}>
      <Headline head={HEAD_NOT_FOUND} subhead={SUBHEAD_NOT_FOUND} firstName={null} eligible={null}/>
    </CardContent>
  );
};

export default ScreenNotFound;