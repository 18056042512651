import React from 'react';
import axios from 'axios';
import CardContent from '@earnup/olympic-lib/CardContent';
import Headline from './Headline';
import LeadForm from './forms/LeadForm';
import queryString from 'query-string';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import { HEAD1, SUBHEAD1 } from './constants/copy';
import { 
  PARTICIPATING_STATES,
  PERCENT,
  createLeadUrl,
  getAmiDateUrl,
 } from './globals/globals';

export const styles = theme => ({
  card: {
    width: '100%',
    padding: 0,
  },
});

const useStyles = makeStyles(styles);

const isLeadEligible = (PERCENT, PARTICIPATING_STATES, amiIncome, amiState, leadIncomeString) => {
  const maxIncome = amiIncome * PERCENT;
  const leadIncomeNumber = parseInt(leadIncomeString.replace(/,/g, ''));
  const approvedIncome = leadIncomeNumber <= maxIncome;
  const approvedState = PARTICIPATING_STATES.indexOf(amiState) > -1;
  return (approvedIncome && approvedState);
}

const isCallGreenpath = query => {
  const queryProperties = queryString.parse(query);
  const queryValue = queryProperties.callgreenpath;
  return queryValue === "true";
}

const ScreenForm = ({ anonymousIdRef, firstNameRef, setCallGreenpath, setEligible, query }) => {
  const classes = useStyles();
  setCallGreenpath(isCallGreenpath(query));

  const screenLead = async (data) => {
    anonymousIdRef.current = window.analytics.user().anonymousId();
    firstNameRef.current = data.firstName;

    const amiData = await axios.get(`${getAmiDateUrl}${data.zipCode}`)
    .then(res => { return res.data })
    .then(data => { return data })
    .catch(err => window.onerror('Error getting AMI', err.stack));
    
    const amiState = amiData.state;
    const amiIncome = amiData.amiCutoff;
    const eligibility = isLeadEligible(PERCENT, PARTICIPATING_STATES, amiIncome, amiState, data.income);

    await axios({
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      url: createLeadUrl,
      data: {
        anonymousId: anonymousIdRef.current,
        qualified: eligibility,
        firstName: data.firstName,
        lastName: data.lastName,
        zipCode: data.zipCode,
        income: data.income,
        phoneNumber: data.phoneNumber,
        emailAddress: data.email,
        preferredContactTime: "",
        agreementAccepted: data.agreement,
        privacyPolicyAccepted: data.privacyPolicy,
      }
    })
    .catch(err => window.onerror('SCREENFORM', err));

    window.analytics.track('submitted', {
      type: 'eligibility',
      value: `${eligibility}`,
    });

    setEligible(eligibility);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  
  return (
    <CardContent className={classes.card}>
      <Headline head={HEAD1} subhead={SUBHEAD1} firstName={null} eligible={null} />
      <LeadForm onSubmit={screenLead} />
    </CardContent>
  );
};

export default ScreenForm;