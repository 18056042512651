import React from 'react';
import { Formik } from 'formik';

import useFormSubmit from '../hooks/useFormSubmit';
import LeadFormForm from './LeadFormForm';
import createValidationSchema from './LeadFormFormValidation';
import {
  getValue,
  getError,
  getHelperText,
  getOnChange,
  getOnBlur,
  getLoading,
  getOnSubmit,
} from '../utils/forms';

const LeadForm = ({ onSubmit: onSubmitProp, initialValues }) => {
  const [success, onSubmit] = useFormSubmit(onSubmitProp);
  const validationSchema = createValidationSchema();
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        phoneNumber: '',
        income: '',
        agreement: false,
        privacyPolicy: false,
      }}
      render={props => {
        const firstNameKey = 'firstName';
        const lastNameKey = 'lastName';
        const emailKey = 'email';
        const zipCodeKey = 'zipCode';
        const phoneNumberKey = 'phoneNumber';
        const incomeKey = 'income';
        const agreementKey = 'agreement';
        const privacyPolicyKey = 'privacyPolicy';
        return (
          <LeadFormForm
            loading={getLoading(props)}
            success={success}
            onSubmit={getOnSubmit(props)}
            firstName={getValue(props, firstNameKey)}
            firstNameError={getError(props, firstNameKey)}
            firstNameHelperText={getHelperText(props, firstNameKey)}
            onFirstNameChange={getOnChange(props, firstNameKey)}
            onFirstNameBlur={getOnBlur(props, firstNameKey)}
            lastName={getValue(props, lastNameKey)}
            lastNameError={getError(props, lastNameKey)}
            lastNameHelperText={getHelperText(props, lastNameKey)}
            onLastNameChange={getOnChange(props, lastNameKey)}
            onLastNameBlur={getOnBlur(props, lastNameKey)}
            email={getValue(props, emailKey)}
            emailError={getError(props, emailKey)}
            emailHelperText={getHelperText(props, emailKey)}
            onEmailChange={getOnChange(props, emailKey)}
            onEmailBlur={getOnBlur(props, emailKey)}
            zipCode={getValue(props, zipCodeKey)}
            zipCodeError={getError(props, zipCodeKey)}
            zipCodeHelperText={getHelperText(props, zipCodeKey)}
            onZipCodeChange={getOnChange(props, zipCodeKey)}
            onZipCodeBlur={getOnBlur(props, zipCodeKey)}
            phoneNumber={getValue(props, phoneNumberKey)}
            phoneNumberError={getError(props, phoneNumberKey)}
            phoneNumberHelperText={getHelperText(props, phoneNumberKey)}
            onPhoneNumberChange={getOnChange(props, phoneNumberKey)}
            onPhoneNumberBlur={getOnBlur(props, phoneNumberKey)}
            income={getValue(props, incomeKey)}
            incomeError={getError(props, incomeKey)}
            incomeHelperText={getHelperText(props, incomeKey)}
            onIncomeChange={getOnChange(props, incomeKey)}
            onIncomeBlur={getOnBlur(props, incomeKey)}
            agreement={getValue(props, agreementKey)}
            agreementError={getError(props, agreementKey)}
            agreementHelperText={getHelperText(props, agreementKey)}
            onAgreementChange={getOnChange(props, agreementKey)}
            onAgreementBlur={getOnBlur(props, agreementKey)}
            privacyPolicy={getValue(props, privacyPolicyKey)}
            privacyPolicyError={getError(props, privacyPolicyKey)}
            privacyPolicyHelperText={getHelperText(props, privacyPolicyKey)}
            onPrivacyPolicyChange={getOnChange(props, privacyPolicyKey)}
            onPrivacyPolicyBlur={getOnBlur(props, privacyPolicyKey)}
          />
        );
      }}
    />
  );
};

export default LeadForm;
