import { useState, useCallback } from './react';

const useFormSubmit = onSubmitProp => {
  const [success, setSuccess] = useState(false);

  const onSubmit = useCallback(
    async (values, options, moreOptions) => {
      const { setSubmitting } = options;
      try {
        await onSubmitProp(values);
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmitProp],
  );

  return [success, onSubmit];
};

export default useFormSubmit;
