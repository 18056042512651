import React from 'react';
import Typography from '@earnup/olympic-lib/Typography';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import Interweave from 'interweave';

export const styles = theme => ({
  content: {
    fontWeight: 'normal',
    marginBottom: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});

const useStyles = makeStyles(styles);

const StepContent = ({ content }) => {
  const classes = useStyles();

  return (
    <Typography
      dataQa="typography-h6"
      variant="h6"
      className={classes.content}
    >
      <Interweave tagName="div" content={content} />
    </Typography>
  );
};

export default StepContent;