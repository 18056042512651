import React from 'react';
import Grid from '@earnup/olympic-lib/Grid';
import Interweave from 'interweave';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import Spacing from '@earnup/olympic-lib/Spacing';
import Typography from '@earnup/olympic-lib/Typography';

export const styles = theme => ({
  head: {
    lineHeight: 1.33,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.125rem',
    },
  }, 
  subhead: {
    lineHeight: 1.5,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: '395px',
    },
  }
});

const useStyles = makeStyles(styles);

const Headline = ({ head, subhead, firstName, eligible }) => {
  const classes = useStyles();
  let headCopy;
  let subheadCopy;
  if (eligible === null) {
    headCopy = head;
    subheadCopy = <Interweave content={subhead} />
  } else if (eligible === true) {
    headCopy = <Interweave content={`Congratulations, ${firstName}!<br>${head}`} />
    subheadCopy = <Interweave content={subhead} />
  } else if (eligible === false) {
    headCopy = <Interweave content={`${firstName}, ${head}`} />
    subheadCopy = <Interweave content={subhead} />
  }
  
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Spacing marginBottom={2}>
        <Typography dataQa="typography-h3" variant="h3" className={classes.head}>
          {headCopy}
        </Typography>
      </Spacing>
      <Spacing>
        <Typography dataQa="typography-body2" variant="body2" className={classes.subhead}>
          {subheadCopy}
        </Typography>
      </Spacing>
    </Grid>
  );
};

export default Headline;