import * as Yup from 'yup';

const time = Yup.string().required('Time of day is required');

const createValidationSchema = () =>
  Yup.object().shape({
    time,
  });

export default createValidationSchema;
