import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@earnup/olympic-lib/Dropdown';
import Form from '@earnup/olympic-lib/Form';
import Gate from '@earnup/olympic-lib/Gate';
import Grid from '@earnup/olympic-lib/Grid';
import Interweave from 'interweave';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import StepContent from '../../StepContent';
import StepDivider from '../../StepDivider';
import StepTitle from '../../StepTitle';
import SubmitButton from '@earnup/olympic-lib/SubmitButton';
import Typography from '@earnup/olympic-lib/Typography';
import { ELIGIBLE_STEPS } from '../../constants/copy';
import { MORNING, AFTERNOON, EVENING } from '../../constants/form';

export const styles = theme => ({
  dropdownGrid: {
    height: theme.spacing.unit * 8.5, //'68px'
  },
  dropdown: {
    flexGrow: 3,
    height: theme.spacing.unit * 7, //'56px'
    paddingRight: theme.spacing.unit,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '199px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '236px',
    },
  },
  dropdownSubmit: {
    flexGrow: 1,
    height: theme.spacing.unit * 7, //'56px'
    [theme.breakpoints.down('xs')]: {
      maxWidth: '88px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '129px',
    },
  },
  confirmation: {
    height: theme.spacing.unit * 8.5, //'68px'
    textAlign: 'center',
  },
});

const useStyles = makeStyles(styles);

const TimeForm = ({
  loading,
  success,
  showform,
  calltime,
  
  time,
  timeError,
  timeHelperText,
  onTimeChange,
  onTimeBlur,
  onSubmit,
}) => {
  const classes = useStyles();

  const dropdown = (
    <Grid 
      container
      direction="row"
      justify="center"
      className={classes.dropdownGrid}
      >
        <Grid item className={classes.dropdown}>
          <Dropdown
            id="-form-time"
            dataQa="time"
            type="text"
            label="Select"
            value={time}
            error={timeError}
            helperText={timeHelperText}
            onChange={onTimeChange}
            onBlur={onTimeBlur}
            list={[
              [MORNING, MORNING],
              [AFTERNOON, AFTERNOON],
              [EVENING, EVENING],
            ]}
          />
        </Grid>
        <Grid item>
          <SubmitButton
            dataQa="submit-button"
            loading={loading}
            success={success}
            className={classes.dropdownSubmit}
          >
            Submit
          </SubmitButton>
        </Grid>
    </Grid>
  );

  const confirmation = (
    <Typography
      dataQa="typography-body2"
      variant="body2"
      className={classes.confirmation}
    >
      <Interweave content={`Got it! We'll give you a call sometime in the <b>${calltime}</b>.`} />
    </Typography>
  );

  return (
    <Form disableAutoComplete onSubmit={onSubmit}>
      <Grid container direction="column" alignItems="center">
        <StepDivider number={ELIGIBLE_STEPS[0].number} />
        <StepTitle title={ELIGIBLE_STEPS[0].title}/>
        <StepContent content={ELIGIBLE_STEPS[0].content} />
        <Gate condition={showform} alternate={confirmation}>
          {dropdown}
        </Gate>
      </Grid>

      <Grid container direction="column" alignItems="center">
        <StepDivider number={ELIGIBLE_STEPS[1].number} />
        <StepTitle title={ELIGIBLE_STEPS[1].title}/>
        <StepContent content={ELIGIBLE_STEPS[1].content} />
      </Grid>

      <Grid container direction="column" alignItems="center">
        <StepDivider number={ELIGIBLE_STEPS[2].number} />
        <StepTitle title={ELIGIBLE_STEPS[2].title}/>
        <StepContent content={ELIGIBLE_STEPS[2].content} />
      </Grid>
    </Form>
  );
};

TimeForm.propTypes = {
  time: PropTypes.string,
  timeError: PropTypes.bool.isRequired,
  timeHelperText: PropTypes.string,
  onTimeChange: PropTypes.func.isRequired,
  onTimeBlur: PropTypes.func.isRequired,
};

export default TimeForm;
