import React from 'react';
import Checkbox from '@earnup/olympic-lib/Checkbox';
import Currency from '@earnup/olympic-lib/Currency';
import Email from '@earnup/olympic-lib/Email';
import Form from '@earnup/olympic-lib/Form';
import FormControl from '@earnup/olympic-lib/FormControl';
import FormGroup from '@earnup/olympic-lib/FormGroup';
import FormHelperText from '@earnup/olympic-lib/FormHelperText';
import Grid from '@earnup/olympic-lib/Grid';
import Link from '@earnup/olympic-lib/Link';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import Phone from '@earnup/olympic-lib/Phone';
import PropTypes from 'prop-types';
import TextField from '@earnup/olympic-lib/TextField';
import Typography from '@earnup/olympic-lib/Typography';
import Spacing from '@earnup/olympic-lib/Spacing';
import SubmitButton from '@earnup/olympic-lib/SubmitButton';
import Zip from '@earnup/olympic-lib/Zip';
import { AGREEMENT, PRIVACY_POLICY_START, PRIVACY_POLICY_END } from '../../constants/form';
import { PRIVACY_POLICY_PDF } from '../../constants/resources';
import { transformTextChangeHandler } from '../utils/events';

export const styles = theme => ({
  next: {
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      width: '295px',
    },
    [theme.breakpoints.up('md')]: {
      width: '276px',
    },
  }, 
});

const useStyles = makeStyles(styles);

const LeadForm = ({
  loading,
  success,
  onSubmit,

  firstName,
  onFirstNameChange,
  onFirstNameBlur,
  firstNameHelperText,
  firstNameError,
  
  lastName,
  onLastNameChange,
  onLastNameBlur,
  lastNameHelperText,
  lastNameError,

  email,
  emailError,
  emailHelperText,
  onEmailChange,
  onEmailBlur,

  zipCode,
  zipCodeError,
  zipCodeHelperText,
  onZipCodeChange,
  onZipCodeBlur,

  phoneNumber,
  phoneNumberError,
  phoneNumberHelperText,
  onPhoneNumberChange,
  onPhoneNumberBlur,

  income,
  incomeError,
  incomeHelperText,
  onIncomeChange,
  onIncomeBlur,

  agreement,
  agreementError,
  agreementHelperText,
  onAgreementChange,
  onAgreementBlur,

  privacyPolicy,
  privacyPolicyError,
  privacyPolicyHelperText,
  onPrivacyPolicyChange,
  onPrivacyPolicyBlur,
}) => {
  const classes = useStyles();

  return (
    <Spacing marginTop={4}>
      <Form disableAutoComplete onSubmit={onSubmit}>
        <FormGroup>
          <Grid container spacing={24}>
            <Grid item xs={12} md={6}>
              <TextField
                id="-form-first-name"
                dataQa="first-name"
                type="text"
                label="First Name"
                value={firstName}
                error={firstNameError}
                helperText={firstNameHelperText}
                onChange={onFirstNameChange}
                onBlur={onFirstNameBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="-form-last-name"
                dataQa="last-name"
                type="text"
                label="Last Name"
                value={lastName}
                error={lastNameError}
                helperText={lastNameHelperText}
                onChange={onLastNameChange}
                onBlur={onLastNameBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Email
                id="-form-email"
                dataQa="email"
                type="text"
                label="Email"
                value={email}
                error={emailError}
                helperText={emailHelperText}
                onChange={onEmailChange}
                onBlur={onEmailBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Zip
                id="-form-zip-code"
                dataQa="zip-code"
                type="text"
                label="Zip Code"
                integer
                value={zipCode}
                error={zipCodeError}
                helperText={zipCodeHelperText}
                onChange={onZipCodeChange}
                onBlur={onZipCodeBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Phone
                id="-form-phone-number"
                dataQa="phone-number"
                type="text"
                label="Mobile Number"
                value={phoneNumber}
                error={phoneNumberError}
                helperText={ phoneNumberHelperText}
                onChange={transformTextChangeHandler(onPhoneNumberChange)}
                onBlur={onPhoneNumberBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Currency
                id="-form-income"
                dataQa="income"
                type="text"
                label="Annual Household Gross Income*"
                value={income}
                error={incomeError}
                helperText={incomeHelperText || '*Income before taxes and insurance'}
                onChange={onIncomeChange}
                onBlur={onIncomeBlur}
              />
            </Grid>
          </Grid>
          <FormControl error={agreementError}>
            <Checkbox
              id="-form-agreement"
              dataQa="agreement"
              type="checkbox"
              checked={agreement}
              error={agreementError}
              value={agreement}
              helperText={agreementHelperText}
              onChange={onAgreementChange}
              onBlur={onAgreementBlur}
              label={AGREEMENT}
              />
            <FormHelperText>{agreementHelperText}</FormHelperText>
          </FormControl>
          <FormControl error={privacyPolicyError}>
            <Checkbox
              id="-form-privacy-policy"
              dataQa="privacy-policy"
              type="checkbox"
              checked={privacyPolicy}
              error={privacyPolicyError}
              value={privacyPolicy}
              helperText={privacyPolicyHelperText}
              onChange={onPrivacyPolicyChange}
              onBlur={onPrivacyPolicyBlur}
              label={
                <Typography gutterBottom dataQa="typography-body2" variant="body2">
                  {`${PRIVACY_POLICY_START}`}
                  <Link
                    dataQa="link-privacy-policy"
                    href={PRIVACY_POLICY_PDF}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                  {`${PRIVACY_POLICY_END}`}
                </Typography>
              }
            />
            <FormHelperText>{privacyPolicyHelperText}</FormHelperText>
          </FormControl>
        </FormGroup>
        <Spacing marginTop={5}>
          <Grid container justify="center" alignContent="stretch">
            <Grid>
              <SubmitButton
                dataQa="submit-button"
                loading={loading}
                success={success}
                className={classes.next}
              >
                See if you're eligible
              </SubmitButton>
            </Grid>
          </Grid>
        </Spacing>
      </Form>
    </Spacing>
  );
};

LeadForm.propTypes = {
  firstName: PropTypes.string,
  firstNameError: PropTypes.bool.isRequired,
  firstNameHelperText: PropTypes.string,
  onFirstNameChange: PropTypes.func.isRequired,
  onFirstNameBlur: PropTypes.func.isRequired,

  lastName: PropTypes.string,
  lastNameError: PropTypes.bool.isRequired,
  lastNameHelperText: PropTypes.string,
  onLastNameChange: PropTypes.func.isRequired,
  onLastNameBlur: PropTypes.func.isRequired,

  email: PropTypes.string,
  emailError: PropTypes.bool.isRequired,
  emailHelperText: PropTypes.string,
  onEmailChange: PropTypes.func.isRequired,
  onEmailBlur: PropTypes.func.isRequired,

  zipCode: PropTypes.string,
  zipCodeError: PropTypes.bool.isRequired,
  zipCodeHelperText: PropTypes.string,
  onZipCodeChange: PropTypes.func.isRequired,
  onZipCodeBlur: PropTypes.func.isRequired,

  phoneNumber: PropTypes.string,
  phoneNumberError: PropTypes.bool.isRequired,
  phoneNumberHelperText: PropTypes.string,
  onPhoneNumberChange: PropTypes.func.isRequired,
  onPhoneNumberBlur: PropTypes.func.isRequired,

  income: PropTypes.string,
  incomeError: PropTypes.bool.isRequired,
  incomeHelperText: PropTypes.string,
  onIncomeChange: PropTypes.func.isRequired,
  onIncomeBlur: PropTypes.func.isRequired,

  agreement: PropTypes.bool,
  agreementError: PropTypes.bool.isRequired,
  agreementHelperText: PropTypes.string,
  onAgreementChange: PropTypes.func.isRequired,
  onAgreementBlur: PropTypes.func.isRequired,

  privacyPolicy: PropTypes.bool,
  privacyPolicyError: PropTypes.bool.isRequired,
  privacyPolicyHelperText: PropTypes.string,
  onPrivacyPolicyChange: PropTypes.func.isRequired,
  onPrivacyPolicyBlur: PropTypes.func.isRequired,
};

export default LeadForm;
