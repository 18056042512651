import upperFirst from 'lodash/upperFirst';

const getEventName = (key, type) => `on${upperFirst(key)}${upperFirst(type)}`;

/**
 * Generates onChange event name for a given key. E.g. scheduleType -> onScheduleTypeChange
 *
 * @param {string} key event key
 */
export const getChangeEventName = key => getEventName(key, 'change');

/**
 * Generates onBlur event name for a given key. E.g. scheduleType -> onScheduleTypeBlur
 *
 * @param {string} key event key
 */
export const getBlurEventName = key => getEventName(key, 'blur');

/**
 * Fakes an event to be consumed by Formik or other libraries which expect
 * a type value from the synthetic event to indicate how to parse the value
 * for form state updates
 *
 * @param {string} type mock event type (e.g. text, number, etc.)
 * @return {function} wrapped handler function
 */
const createTransformChangeHandler = type => handler => (event, value) => {
  const eventMock = {
    type,
    target: {
      value,
    },
  };

  handler(eventMock, value);
};

/**
 * Transforms event callbacks of the form `(event, value) => {}`, where
 * `value` is a `string` into `event => {}` form.
 *
 * @param {function} textChangeHandler inner text change handler
 * @returns {function} enhanced text change handler
 */
export const transformTextChangeHandler = createTransformChangeHandler('text');

/**
 * Transforms event callbacks of the form `(event, value) => {}`, where
 * `value` is a `number` into `event => {}` form.
 *
 * @param {function} numericChangeHandler inner number change handler
 * @returns {function} enhanced number change handler
 */
export const transformNumericChangeHandler = createTransformChangeHandler('number');

/**
 * Transforms event callbacks of the form `(event, value) => {}`, where
 * `value` is a `radio` into `event => {}` form.
 *
 * @param {function} radioChangeHandler inner radio change handler
 * @returns {function} enhanced radio change handler
 */
export const transformRadioChangeHandler = createTransformChangeHandler('radio');

export const handleOnChange = func => (event, value) => func(value);

export const wrapFunction = (func, ...args) => () => func(...args);
