import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CardContent from '@earnup/olympic-lib/CardContent';
import Gate from '@earnup/olympic-lib/Gate';
import Headline from './Headline';
import Image from './Image';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import TimeForm from './forms/TimeForm/TimeForm'
import LeadCallsGreenpath from './LeadCallsGreenpath';
import { HEAD2, SUBHEAD2, SUBHEAD2_GREENPATH } from './constants/copy';
import { ELIGIBLE_MOBILE } from './constants/resources';
import { updateLeadContactTimeUrl } from './globals/globals';

export const styles = theme => ({
  card: {
    width: '100%',
    padding: 0,
  },
});

const useStyles = makeStyles(styles);

const ScreenEligible = ({ anonymousIdRef, callGreenpath, eligible, firstNameRef }) => {
  const classes = useStyles();
  const calltimeRef = useRef('');
  const [showform, setShowform] = useState(true);

  useEffect(() => {
    if (showform) window.analytics.page();
  });

  const createTime = async (data) => {
    await axios({
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      url: updateLeadContactTimeUrl,
      data: {
        anonymousId: anonymousIdRef.current,
        preferredContactTime: data.time.toLowerCase(),
      }
    })
    .catch(err => window.onerror('SCREENELIGIBLE', err.stack));

    calltimeRef.current = data.time.toLowerCase();
    setShowform(false);
  }
  
  const timeForm = (
    <TimeForm onSubmit={createTime} showform={showform} calltime={calltimeRef.current}/>
  );

  return (
  <CardContent className={classes.card}>
    <Image alt={"congratulations-illustration-mobile"} src={ELIGIBLE_MOBILE} />
    <Headline head={HEAD2} subhead={callGreenpath ? SUBHEAD2_GREENPATH : SUBHEAD2} firstName={firstNameRef.current} eligible={eligible}/>
    <Gate condition={callGreenpath} alternate={timeForm}>
      <LeadCallsGreenpath />
    </Gate>
  </CardContent>
  );
};

export default ScreenEligible;