import React, { useEffect } from 'react';
import CardContent from '@earnup/olympic-lib/CardContent';
import Headline from './Headline';
import Image from './Image';
import makeStyles from '@earnup/olympic-lib/styles/makeStyles';
import { HEAD3, SUBHEAD3 } from './constants/copy';
import { NOT_ELIGIBLE_MOBILE } from './constants/resources';

export const styles = theme => ({
  card: {
    width: '100%',
    padding: 0,
  },
});

const useStyles = makeStyles(styles);

const ScreenNotEligible = ({ eligible, firstNameRef }) => {
  const classes = useStyles();

  useEffect(() => {
    window.analytics.page();
  });

  return (
    <CardContent className={classes.card}>
      <Image alt={"not eligible-mobile"} src={NOT_ELIGIBLE_MOBILE} />
      <Headline head={HEAD3} subhead={SUBHEAD3} firstName={firstNameRef.current} eligible={eligible}/>
    </CardContent>
  );
};

export default ScreenNotEligible;