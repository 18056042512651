import React from 'react';
import Typography from '@earnup/olympic-lib/Typography';
import Divider from '@earnup/olympic-lib/Divider';
import { makeStyles } from '@earnup/olympic-lib/styles';

export const styles = theme => ({
  number: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    boxShadow: `0 0 0 20px ${theme.palette.common.white}`,
    color: theme.palette.primary.contrastText,
    fontWeight: 900,
    height: theme.spacing.unit * 6,
    width: theme.spacing.unit * 6,
  },
  numberContainer: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'translateY(100%)',
  },
  stepDividerContainer: {
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

const StepDivider = ({ number }) => {
  const classes = useStyles();

  return (
    <div className={classes.stepDividerContainer}>
      <div className={classes.numberContainer}>
        <Typography dataQa="typography-h4" variant="h4" className={classes.number}>
          {number}
        </Typography>
      </div>
      <Divider variant="middle" />
    </div>
  );
};

export default StepDivider;