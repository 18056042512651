import * as Yup from 'yup';
import { MAX_HHI } from '../../constants/form';

const firstName = Yup.string().required('First name is required');
const lastName = Yup.string().required('Last name is required');
const email = Yup.string()
  .required('Email is required')
  .email('Invalid email address. Use the format x@x.x');
const zipCode = Yup.string()
  .required('Zip code is required')
  .matches(/^(?!0{3})[0-9]{3,5}$/, 'Must be valid U.S. zip code') // Cannot contain more than 2 leading 0's. 
  .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Must be valid U.S. zip code'); // Validates US Zip Codes(5 digit and 5-4 digit formats). 
const phoneNumber = Yup.string()
  .required('Mobile number is required')
  .min(10, 'Mobile number must be formatted as: 415-555-5555')
  .max(10, 'Mobile number must be formatted as: 415-555-5555');
const income = Yup.string()
  .required('Annual household gross income is required')
  .transform(function(value, originalvalue) {
    return this.isType(value) && value !== null ? parseInt(value.replace(/,/g, '')).toString() : value;
  })
  .max(6, `Annual household gross income must be less than ${MAX_HHI}`);
const agreement = Yup.boolean()
  .required("Terms and conditions must be accepted")
  .oneOf([true], "Terms and conditions must be accepted")
const privacyPolicy = Yup.boolean()
  .required("Privacy policy must be accepted")
  .oneOf([true], "Privacy policy must be accepted")

const createValidationSchema = () =>
  Yup.object().shape({
    firstName,
    lastName,
    email,
    zipCode,
    phoneNumber,
    income,
    agreement,
    privacyPolicy,
  });
export default createValidationSchema;
