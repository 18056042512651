// Qualification
export const PARTICIPATING_STATES = ['AL', 'LA', 'MS', 'MI'];
export const PERCENT = 0.85;

// AMI API
export const getAmiDateUrl = `https://j3i98lfg12.execute-api.us-west-2.amazonaws.com/prod/ami?zip=`;

// Leads API
export const createLeadUrl = `https://eejtxuob28.execute-api.us-west-2.amazonaws.com/prod/lead`;
export const updateLeadContactTimeUrl = `https://eejtxuob28.execute-api.us-west-2.amazonaws.com/prod/preferred_contact_time`;
